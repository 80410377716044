import axios from "axios";
import baseURL from "../config/baseURL";
import { defer } from "react-router-dom";

function buildURL(url) {
  const endpoint = new URL(url.pathname, baseURL);
  url.searchParams.forEach((value, key) => {
    endpoint.searchParams.set(key, value);
  });
  return endpoint.toString();
}

async function emailPost(email) {
  const url = new URL('create-magic-link', baseURL);
  const responsePromise = axios.post(
    buildURL(url),
    {
      email,
    }
  );

  return defer({
    email: responsePromise,
  });
}

async function reportLoader({ request }) {
  const url = new URL(request.url);
  const responsePromise = axios.get(
    buildURL(url),
    {
      withCredentials: true,
    }
  );

  return defer({
    report: responsePromise,
  });
};

export { reportLoader, emailPost };