import React from "react";
import { NavLink } from "react-router-dom";
import { Gear } from "@rsuite/icons";
import { Nav } from "rsuite";

const ForwardedNavLink = React.forwardRef(({ href, children, navicon, ...rest }, ref) => {
  const getIcon = (isPending) => {
    if (isPending) {
      return <Gear spin />;
    } else {
      return navicon;
    }
  }
  return (
    <NavLink ref={ref} to={href} {...rest}>
      {({ _, isPending }) => (
        <>
          {getIcon(isPending)}&nbsp;
          {children}
        </>
      )}
    </NavLink>
  );
});

export default function WrappedNavItem({ href, text, icon, indent }) {
  const indentspan = <span style={{ display: "inline-block", width: 15 }}></span>;
  return (
    <Nav.Item as={ForwardedNavLink} href={href} navicon={icon}>{indent ? indentspan : ''}{text}</Nav.Item>
  )
}
