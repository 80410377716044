import React from 'react';

import { useLoaderData, useSearchParams, Await } from "react-router-dom";

import DataTable from "./DataTable";
import TimeSeriesChart from "./TimeSeriesChart";

import { Container, Panel, Stack, Button, ButtonGroup } from 'rsuite'

import { Content } from 'rsuite';
import ReportSidebar from './ReportSidebar';
import LoadingGimmick from './LoadingGimmick';
import CategoricalBarChart from './CategoricalBarChart';
import PieChart from './PieChart';

import { AppContext } from '../contexts/appContext';
import { FunnelChart, Tooltip, LabelList, Funnel } from 'recharts';

function toListOfObjects(headings, data) {
  const list = [];
  for (let i = 0; i < data.length; i++) {
    const row = {};
    for (let j = 0; j < headings.length; j++) {
      row[headings[j]] = data[i][j];
    }
    list.push(row);
  }
  return list;
}

function Report() {
  const data = useLoaderData();
  const { reports } = React.useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const filterContext = (data) => {
    if (data.context) {
      const clearFilter = () => {
        const { article, author, category, ...rest } = Object.fromEntries(searchParams);
        setSearchParams(rest);
      }
      const link = 'https://' + data.context.hostname + data.context.pagepath;
      const filterInfo = (data) => {
        if (searchParams.has('article')) {
          return (
            <>
              <strong>{data.context.authorname}</strong>&nbsp;|&nbsp;
              Primary Category: <strong>{data.context.primarycategory}</strong>&nbsp;|&nbsp;
              <a href={link} target="_blank" rel="noreferrer">View on {data.context.hostname}</a>
            </>
          )
        }
        if (searchParams.has('author')) {
          return (<h3>{data.context.authorname}</h3>)
        }
        if (searchParams.has('category')) {
          return (<h3>{data.context.categoryname}</h3>)
        }
      }
      return (
        <Container style={{ width: 700, marginBottom: 20 }}>
          <Panel header={
            <Stack justifyContent="space-between">
              <span>Filtered View: {data.context.filtertitle}</span>
              <ButtonGroup>
                <Button onClick={clearFilter}>Clear Filter</Button>
              </ButtonGroup>
            </Stack>
          } bordered>
            <h4>{data.context.title}</h4>
            <p>
              {filterInfo(data)}
            </p>
          </Panel>
        </Container>
      )
    }
    return '';
  }
  
  const visualizations = (data) => {
    const dataObj = toListOfObjects(data.headings, data.data);
    return data.config.render.map(
      (type, index) => {
        if (type === 'time_series_chart') {
          return <TimeSeriesChart key={index} headings={data.headings} data={dataObj} />
        }
        if (type === 'time_series_table') {
          return <DataTable
            key={index}
            headings={data.headings}
            data={dataObj}
          />
        }
        if (type === 'bar_chart') {
          return <CategoricalBarChart key={index} data={dataObj.slice(0, 20)} label={data.headings[1]} legend={data.headings[0]} />
        }
        if (type === 'pie_chart') {
          return <PieChart key={index} data={dataObj.slice(0, 20)} label={data.headings[1]} legend={data.headings[0]} />
        }
        if (type === 'funnel_chart') {
          return (
            <FunnelChart width={730} height={250}>
              <Tooltip />
              <Funnel
                dataKey="Event Count"
                data={dataObj}
                isAnimationActive
                fill="#8884d8"
              >
                <LabelList position="center" fill="#000" stroke="none" dataKey="Event" />
              </Funnel>
            </FunnelChart>
          )
        }
        if (type === 'categorical_table') {
          return <DataTable
            key={index}
            headings={data.headings}
            data={dataObj}
            wideFirstColumn={true}
          />
        }
        return <p key={index}>No visualization is available for {type}.</p>;
      }
    )
  };
  return (
    <>
      <ReportSidebar reports={reports} />
      <Content>
        <React.Suspense fallback={<LoadingGimmick hasSidebar={true} />}>
          <Await resolve={data.report} errorElement={<p>An unexpected error occurred.</p>}>
            {(result) => (
              <div>
                <h1>{result.data.config.title}</h1>
                {filterContext(result.data)}
                {visualizations(result.data)}
              </div>
            )}
          </Await>
        </React.Suspense>
      </Content>
    </>
  )
}

export default Report;