import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'rsuite';

const stripQuotes = (str) => {
   if (str.charAt(0) === '"' && str.charAt(str.length -1) === '"') {
    return str.substring(1, str.length - 1);
   }
   return str;
}

const FormattedCell = ({ rowData, dataKey, rowIndex, ...props }) => {
  const getLabel = (label) => {
    if (!label) {
      return '';
    }
    if (Array.isArray(label)) {
      return <Link to={`/engagement/summary?${label[1]}=${label[2]}`}>{stripQuotes(label[0])}</Link>;
    }
    // show integers with commas
    if (Number.isInteger(label)) {
      return label.toLocaleString();
    }
    if (typeof label === 'string') {
      return stripQuotes(label);
    }
    return label;
  }
  return (
    <Table.Cell {...props}>
      {getLabel(rowData[dataKey])}
    </Table.Cell>
  );
}

function TimeSeriesTable({ headings, data, wideFirstColumn }) {
  const tableWidth = headings.length * 150 + (wideFirstColumn ? 350 : 0);
  return (
    <div>
      <Table
        data={data}
        height={400}
        width={tableWidth}
      >
        {headings.map((heading, index) => {
          const colWidth = index === 0 && wideFirstColumn ? 500 : 150;
          return <Table.Column key={index} width={colWidth} align="left" sortable>
            <Table.HeaderCell>{heading}</Table.HeaderCell>
            <FormattedCell dataKey={heading} />
          </Table.Column>
        })}
      </Table>
    </div>
  )
}

export default TimeSeriesTable;