import React, { useState } from 'react';
import { Panel, DateRangePicker } from 'rsuite';
import { useSearchParams } from 'react-router-dom';

import { parse } from 'date-fns';

const today = new Date();

function strToDate(str) {
  return parse(str, 'yyyy-MM-dd', new Date());
}

function sevenDaysAgo() {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return date;
}

function dateToStr(date) {
  return date.toISOString().slice(0, 10);
}

function shouldDisableDate(earliestDate, date) {
  return date < earliestDate || date > today;
}

function ReportDateRange({ earliest, onRangeUpdate }) {
  const earliestDate = strToDate(earliest);

  const [searchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(searchParams.get('start_date') || dateToStr(sevenDaysAgo()));
  const [endDate, setEndDate] = useState(searchParams.get('end_date') || dateToStr(today));
  return (
    <Panel>
      <DateRangePicker
        value={[strToDate(startDate), strToDate(endDate)]}
        shouldDisableDate={(date) => shouldDisableDate(earliestDate, date)}
        limitEndYear={0}
        onOk={(value) => {
          setStartDate(dateToStr(value[0]));
          setEndDate(dateToStr(value[1]));
          const searchParams = {
            'start_date': dateToStr(value[0]),
            'end_date': dateToStr(value[1])
          };
          onRangeUpdate(searchParams);
        }}
      />
    </Panel>
  )
}

export default ReportDateRange;