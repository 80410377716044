import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import baseURL from "../config/baseURL";
import cookieURL from "../config/cookieURL";

import LoadingGimmick from "../components/LoadingGimmick";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const AppContext = createContext();
const AppDispatchContext = createContext();

const AppProvider = ({ children }) => {
  const [plan, setPlan] = useState(undefined);
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies();
  const setActivePublisher = (publisher) => {
    setPlan({...plan, activePublisher: publisher});
    setCookie('publisher', publisher, { path: '/', domain: cookieURL });
    navigate(0);
  };
  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`${baseURL}/explain`, { withCredentials: true });
      setPlan({...data, activePublisher: cookie.publisher || 'all'});
    }
    fetchData();
  }, [cookie.publisher]);
  if (plan === undefined) {
    return <LoadingGimmick />;
  }
  return (
    <AppContext.Provider value={plan}>
      <AppDispatchContext.Provider value={setActivePublisher}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};

export { AppContext, AppDispatchContext, AppProvider };