import React from 'react';
import { Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

const colors = ['#003a7d', '#008dff', '#ff73b6', '#c701ff', '#4ecb8d', '#ff9d3a', '#f9e858', '#d83034'];

function TimeSeriesChart({ data, headings }) {
  return (
    <div>
      <LineChart width={800} height={400} data={data}>
        <XAxis dataKey={headings[0]} />
        <YAxis dataKey={headings[1]} />
        <Tooltip />
        <Legend />
        {headings.slice(1).map((heading, index) => (
          <Line type="monotone" dataKey={heading} stroke={colors[index]} />
        ))}
      </LineChart>
    </div>
  )
}

export default TimeSeriesChart;