import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";

export default function BeaconPieChart({ data, label, legend }) {
  const COLORS = [
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600'
  ];
  return (
    <PieChart width={600} height={400} data={data}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          outerRadius={150}
          fill="#8884d8"
          paddingAngle={2}
          nameKey={legend}
          dataKey={label}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
}