import React from "react";

import { PieChart } from "@rsuite/icons";

export default function LoadingGimmick({ hasSidebar }) {
  return (
    <div className="loading-gimmick">
      <p><PieChart spin /> Loading...</p>
    </div>
  );
}