import React, { useState } from 'react';
import { Drawer, Button } from 'rsuite';
import { useSearchParams } from 'react-router-dom';

function ReportFilters({ closeDrawerHandler, report, dateRange }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const allowedFilters = report.filters || [];
  const getInitialFilters = () => {
    const filters = {};
    allowedFilters.forEach(filter => {
      if (searchParams.has(filter)) {
        filters[filter] = searchParams.get(filter);
      }
    });
    return filters;
  }

  const [filterState, setFilterState] = useState(getInitialFilters);

  const makeQueryDict = () => {
    const fullQuery = {...dateRange, ...filterState};
    Object.keys(fullQuery).forEach((key) => {
      if (fullQuery[key] === null) {
        delete fullQuery[key];
      }
    });
    return fullQuery;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    closeDrawerHandler();
    setSearchParams(makeQueryDict)
  };

  const handleCheckboxClick = (e) => {
    if (e.target.checked) {
      setFilterState({ ...filterState, [e.target.name]: 'true' });
    } else {
      const { [e.target.name]: _, ...rest } = filterState;
      setFilterState(rest);
    }
  };

  const handleSelectChange = (e) => {
    if (e.target.value !== '') {
      setFilterState({ ...filterState, [e.target.name]: e.target.value });
    } else {
      const { [e.target.name]: _, ...rest } = filterState;
      setFilterState(rest);
    }
  };
  
  const botsCheckbox = () => {
    if (allowedFilters.includes('bots')) {
      return (
        <div className="filter">
          <label>
            <input type="checkbox" name="bots" value="true" checked={filterState['bots'] === 'true'} onChange={handleCheckboxClick} />&nbsp;
            Include Traffic from Bots and Crawlers
          </label>
        </div>
      );
    }
  };

  const segmentDropdown = () => {
    if (allowedFilters.includes('segment')) {
      return (
        <div className="filter">
          <label>
            Segment Using:&nbsp;
            <select name="segment" onChange={handleSelectChange} value={filterState['segment'] || ""}>
              <option value="">None</option>
              <option value="user_status">User Status</option>
              <option value="device">Device</option>
            </select>
          </label>
        </div>
      );
    }
  };

  const resolutionDropdown = () => {
    if (allowedFilters.includes('resolution')) {
      return (
        <div className="filter">
          <label>
            Resolution:&nbsp;
            <select name="resolution" onChange={handleSelectChange} value={filterState['resolution'] || ""}>
              <option value="">Day (default)</option>
              <option value="hour">Hour</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </label>
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Drawer.Header>
        <Drawer.Title>Filters</Drawer.Title>
        <Drawer.Actions>
          <Button onClick={closeDrawerHandler}>Cancel</Button>
          <Button type="submit" appearance="primary">
            Confirm
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        {botsCheckbox()}
        {segmentDropdown()}
        {resolutionDropdown()}
      </Drawer.Body>
    </form>
  );
}

export default ReportFilters;