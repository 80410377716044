import React from 'react';
import { Content, Panel } from 'rsuite';

function Home() {
  return (
    <Content>
      <Panel className="readme">
        <h3>Welcome to Lede Beacon Beta</h3>
        <p>Lede Beacon is a tool to better understand the performance of your website.</p>
        <p>This is beta software! Please do not use Lede's helpdesk functionality to request assistence with this tool! Regular office hours will be shared by the Lede team for Q&A during the beta period of this tool.</p>
        <p>The <strong>engagement</strong> tab shares various reports that allow you understand how traffic arrives at your site as well as the magnitude of traffic, similar to what you might see in a basic Google Analytics report.</p>
        <p>The <strong>acquisition</strong> tab shares information about what users do before purchasing a subscription or signing up for an email.</p>
      </Panel>
    </Content>
  );
}

export default Home;
