import { BarChart, XAxis, YAxis, Tooltip, Bar, Legend } from "recharts";

export default function CategoricalBarChart({ data, label, legend }) {
  return (
    <BarChart width={730} height={250} data={data}>
      <XAxis dataKey={legend} tick={false} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey={label} fill="#8884d8" />
    </BarChart>
  );
}