import React from "react";
import { AppContext, AppDispatchContext } from "../contexts/appContext";
import { SelectPicker } from "rsuite";

function PublisherPicker() {
  const { publishers, activePublisher } = React.useContext(AppContext);
  const setActivePublisher = React.useContext(AppDispatchContext);
  if (!publishers || publishers.length === 1) {
    return '';
  }
  return (
    <SelectPicker
      label="Publisher"
      data={publishers}
      value={activePublisher}
      size="xs"
      onChange={(value) => setActivePublisher(value)}
    />
  );
}

export default PublisherPicker;