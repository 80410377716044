import React, { useState } from 'react';

import { useRouteError } from 'react-router-dom';
import { Container, Content, FlexboxGrid, Header, Panel } from 'rsuite';

import { WarningRound } from '@rsuite/icons';
import { emailPost } from '../loaders/reportLoader';

function ErrorIntercept() {
  const error = useRouteError();
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const errorMessage = () => {
    if (error.name === 'AxiosError' && error.response) {
      return error.response.data.message;
    }
    if (error.message) {
      return error.message;
    }
    return 'An unknown error occurred.';
  };
  const errorTitle = () => {
    if (errorMessage() === 'Access Denied') {
      return 'Authentication Required';
    }
    return 'Error';
  };
  const emailSubmit = (e) => {
    e.preventDefault();
    setEmailSubmitted(true);
    emailPost(email);
  }
  const errorDetail = () => {
    const msg = errorMessage();
    if (msg === 'Access Denied') {
      if (emailSubmitted) {
        return (<p>👍 If the email you supplied is associated with an account, you will receive an email with a sign-in link shortly.</p>);
      }
      return (
        <form onSubmit={emailSubmit}>
          <label htmlFor="email">Enter your email to receive a one-time link to authenticate:</label>
          <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" size="30" />
          <input type="submit" value="Submit" />
        </form>
      );
    }
    return (
      <p>{msg}</p>
    )
  }
  return (
    <Container className="error-page" style={{ marginTop: '25px' }}>
      <Header />
      <Content className="fullwidth">
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={6}>
            <Panel header={<h3><WarningRound />&nbsp;{errorTitle()}</h3>} bordered>
              {errorDetail()}
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
}

export default ErrorIntercept;
